import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Slide,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { ChangeEvent, useEffect, useState } from "react";
import { db } from "../FirebaseContext";
import { useStoreState } from "../hooks/storeHooks";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function valuetext(value: number) {
  return `${value} min`;
}

const Settings = () => {
  const classes = useStyles();
  const [time, setTime] = useState<number>(15);
  const [dialog, setDialog] = useState<boolean>(false);

  useEffect(() => {
    db.collection("settings")
      .doc("waitingTime")
      .onSnapshot((doc) => {
        if (doc?.data()?.waitingTime) {
          setTime(doc?.data()?.waitingTime);
        }
      });
  }, []);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setTime(newValue as number);
  };
  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTime(Number(event.target.value));
  };
  const audio = useStoreState((state) => state.audio);

  const play = () => {
    audio.play();
  };
  return (
    <>
      <Card className={classes.card}>
        <Typography id="discrete-slider" gutterBottom>
          Ventetid
        </Typography>
        <div className={classes.row}>
          <Slider
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={5}
            value={time}
            onChange={handleSliderChange}
            marks
            min={0}
            max={120}
          />
          <TextField
            label="ventetid"
            type="number"
            value={time}
            onChange={handleInputChange}
            variant="outlined"
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            db.collection("settings")
              .doc("waitingTime")
              .set({ waitingTime: time })
              .then(() => {
                setDialog(true);
              })
              .catch((error) => {
                console.log(error);

                alert(
                  "Det skjedde en feil under oppdateringen, kontakt support"
                );
              });
          }}
        >
          Oppdater
        </Button>
      </Card>

      <Card className={classes.card}>
        <Typography id="discrete-slider" gutterBottom>
          Lyd
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            play();
          }}
        >
          Spill lyd
        </Button>
      </Card>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ventetid er oppdatert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ny ventetid er {valuetext(time)}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Settings;
