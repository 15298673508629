import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Food from "../constants/Food";
import Order from "../constants/order";
import { db } from "../FirebaseContext";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";
// import * as admin from "firebase-admin";
import MenuItem from "../constants/menuItem";
import firebase from "firebase";
type FoodOrder = {
  dbId?: string;
  created: Date;
  pickupDate: Date;
  name: string;
  phone: string;
  comment: string;
  dish: string;
  foodId: string;
  amount: number;
  state: "started" | "new" | "finished" | "delivered";
  price: number;
};

const Orders = () => {
  const menu = useStoreState((state) => state.menu);
  const orders = useStoreState((state) => state.orders);
  //Special array for displaying all foodOrders from all orders
  const [foodOrders, setfoodOrders] = useState<FoodOrder[]>([]);

  useEffect(() => {
    const _foodOrders: FoodOrder[] = [];
    orders.forEach((order) => {
      order.food.forEach((f: Food) => {
        var menuItem = menu.find((mi) => mi.id === f.id) as MenuItem;
        const thresholdDate = moment(new Date()).subtract(12, "hours");
        if (
          !moment(order.pickupDate).isBefore(thresholdDate) &&
          f.state != "delivered"
        ) {
          _foodOrders.push({
            dbId: order.dbId,
            created: order.pickupDate,
            pickupDate: order.pickupDate,
            name: order.name,
            phone: order.phone,
            comment: order.comment,
            dish: menuItem?.name,
            amount: f.amount,
            state: f.state,
            foodId: f.id,
            price: f.price,
          });
        }
      });
    });
    setfoodOrders(_foodOrders);
  }, [orders]);

  return (
    <>
      <MaterialTable
        options={{
          exportButton: true,
          pageSize: 20,
        }}
        cellEditable={{
          onCellEditApproved: (
            newValue,
            oldValue,
            rowData: FoodOrder,
            columnDef
          ) => {
            return new Promise((resolve, reject) => {
              var orderRef = db.collection("orders").doc(rowData.dbId);
              // console.log("newValue: " + newValue);
              // console.log("rowData: ", rowData);

              var changefood: Food = {
                amount: rowData.amount,
                id: rowData.foodId,
                name: rowData.dish,
                price: rowData.price,
                state: newValue,
              };

              orderRef
                .get()
                .then((doc) => {
                  // Assign array to local javascript variable
                  var objects: Food[] = doc.data()?.food;

                  // Assing desired element of object to local javascript variable
                  var objectToUpdate = objects.find(
                    (f) => f.id === rowData.foodId
                  );
                  if (!objectToUpdate) {
                    reject();
                    return;
                  }
                  const idx = objects.indexOf(objectToUpdate);
                  // Update field of the element assigned to local javascript variable
                  objectToUpdate = changefood;

                  // reassign object to local array variable
                  objects[idx] = objectToUpdate;

                  // Update complete array with update copy of element we have
                  // created in local javascript variable.
                  orderRef
                    .update({ food: objects })
                    .then(() => resolve())
                    .catch(() => reject());
                })
                .catch(() => reject());
            });
          },
        }}
        columns={[
          { title: "Antall", field: "amount", width: "2px", editable: "never" },
          { title: "Rett", field: "dish", editable: "never" },
          { title: "Navn", field: "name", editable: "never" },
          // { title: "Telefon", field: "phone", editable: "never" },
          { title: "Kommentar", field: "comment", editable: "never" },

          // {
          //   title: "Tidspunkt på bestilling",
          //   field: "created",
          //   type: "datetime", editable: "never"
          // },
          {
            title: "Hentetid",
            field: "pickupDate",
            type: "datetime",
            defaultSort: "asc",
            render: (rowData) => moment(rowData.pickupDate).format("lll"),
            editable: "never",
          },

          {
            title: "Status",
            field: "state",
            lookup: {
              new: "Ny",
              started: "Tilbredes",
              finished: "Ferdig",
              delivered: "Levert",
            },
          },
        ]}
        data={foodOrders}
        title="Bestillinger (med hentetid for 12 timer siden eller nyere)"
      />
    </>
  );
};

export default Orders;
