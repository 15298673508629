import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { db } from "../FirebaseContext";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Apps,
  Contacts,
  Receipt,
  Settings,
  TimelineOutlined,
  ViewList,
} from "@material-ui/icons";

import { motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";
import MenuItem from "../constants/menuItem";
import logo from "./../assets/img/logo.svg";
import Order from "../constants/order";

const drawerWidth = 240;
const routeKeys: any = {
  "/login": {
    hide: true,
    title: "Login",
  },
  "/": {
    hide: true,
    title: "Meny",
  },
  "/editMenu": {
    hide: false,
    title: "Endre meny",
  },
  "/admin": {
    hide: false,
    title: "Endre meny",
  },
  "/visualization": {
    hide: false,
    title: "Visualisering",
  },
  "/personOrders": {
    hide: false,
    title: "Bestillnger per person",
  },
  "/orders": {
    hide: false,
    title: "Bestillinger",
  },
  "/settings": {
    hide: false,
    title: "Instillinger",
  },
  "/signIn": {
    hide: true,
    title: "Login",
  },
  "/orderResponse": {
    hide: true,
    title: "Kvittering",
  },
  "/orderInfo": {
    hide: true,
    title: "Kvittering",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: 80,
    padding: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
  },
  userContent: {
    flexGrow: 1,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
const Layout = (props: any) => {
  const location = useLocation();
  let history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const setMenu = useStoreActions((actions) => actions.setMenu);
  const orders = useStoreState((state) => state.orders);
  const audio = useStoreState((state) => state.audio);
  const setOrders = useStoreActions((actions) => actions.setOrders);
  // const [ordersCount, setOrdersCount] = useState<number>(-1);
  const toggleOpen = useStoreActions((actions) => actions.toggleDrawer);
  const open = useStoreState((state) => state.drawerOpen);
  const [title, setTitle] = useState<string>("");
  const [hideLayout, setHideLayout] = useState<boolean>();
  const handleDrawerOpen = () => {
    toggleOpen(true);
  };

  useEffect(() => {
    setHideLayout(routeKeys[location.pathname]?.hide);
    setTitle(routeKeys[location.pathname]?.title);
  }, [location]);

  const handleDrawerClose = () => {
    toggleOpen(false);
  };
  const play = () => {
    audio.play();
  };
  useEffect(() => {
    let mounted = true;

    db.collection("menu").onSnapshot(
      (collectionSnapshot) => {
        const data = collectionSnapshot.docs.map((doc) => {
          const _data = doc.data();
          _data.id = doc.id;
          return _data;
        });
        const menuItemsData: MenuItem[] = [];
        data.forEach((menuItemDoc) => {
          menuItemsData.push({
            description: menuItemDoc.description,
            id: menuItemDoc.id,
            image: menuItemDoc.image,
            name: menuItemDoc.name,
            price: menuItemDoc.price,
            deleted: menuItemDoc.deleted,
          });
        });
        setMenu(menuItemsData);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    // On admin site:
    if (!routeKeys[location.pathname]?.hide) {
      db.collection("orders").onSnapshot((collectionSnapshot) => {
        var playFlag = false;
        collectionSnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            playFlag = true;
            // console.log("New: ", change.doc.data());
          }
          if (change.type === "modified") {
            // console.log("Modified: ", change.doc.data());
          }
          if (change.type === "removed") {
            // console.log("Removed: ", change.doc.data());
          }
        });
        if (playFlag) {
          play();
        }

        const data = collectionSnapshot.docs.map((doc) => {
          let _fOrder = doc.data();
          _fOrder.dbId = doc.id;
          return _fOrder;
        });
        if (data.length === 0) {
          return;
        }

        const _orders: Order[] = [];
        data.forEach(
          (order) => {
            _orders.push({
              created: new Date(order.created.seconds * 1000),
              pickupDate: new Date(order.pickupDate.seconds * 1000),
              name: order.name,
              phone: order.phone,
              comment: order.comment,
              food: order.food,
              finishedOrder: order.finishedOrder,
              accepted: order.accepted,
              dbId: order.dbId,
            });
          },
          (err: any) => {
            console.log(`Encountered error: ${err}`);
          }
        );
        setOrders(_orders);
      });
    }
  }, []);

  if (hideLayout) {
    return (
      <>
        {process.env.REACT_APP_ENV === "development" ? "testside" : <></>}
        <header className={classes.header}>
          <img
            src={logo}
            alt="Logo"
            style={{ cursor: "pointer", width: "300px" }}
            onClick={() => {
              history.push("/");
              window.location.reload(false);
            }}
          />
        </header>
        <div className={classes.userContent}>{props.children}</div>
      </>
    );
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography variant="h6" noWrap>
              {title}
            </Typography>

            {/* <AppbarDropdownMenu /> */}
            {process.env.REACT_APP_ENV === "development" ? (
              <p>Testside</p>
            ) : (
              <></>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            { title: "Meny", path: "/", icon: <Apps /> },
            { title: "Endre meny", path: "/editMenu", icon: <Receipt /> },
            { title: "Bestillinger", path: "/orders", icon: <ViewList /> },
            {
              title: "Bestillinger per person",
              path: "/personOrders",
              icon: <Contacts />,
            },
            // {
            //   title: "Visualisering",
            //   path: "/visualization",
            //   icon: <TimelineOutlined />,
            // },
            {
              title: "Instillinger",
              path: "/settings",
              icon: <Settings />,
            },
          ].map((object, index) => (
            <ListItem
              button
              key={object.title}
              onClick={() => {
                history.push(object.path);
              }}
              // selected={history.listen.name == object.path}
            >
              <ListItemIcon>{object.icon}</ListItemIcon>
              <ListItemText primary={object.title} />
            </ListItem>
          ))}
          <Divider />
        </List>
      </Drawer>
      <motion.main
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0,
            translateY: 20,
          },
          pageAnimate: {
            opacity: 1,
            translateY: 0,
          },
        }}
        transition={{ duration: 0.7 }}
        className={classes.content}
      >
        {props.children}
      </motion.main>
    </div>
  );
};
export default Layout;
