import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Order from "../constants/order";
import { db } from "../FirebaseContext";
import { useStoreState } from "../hooks/storeHooks";

const PersonOrders = () => {
  const menu = useStoreState((state) => state.menu);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    if (menu) {
      db.collection("orders").onSnapshot(
        (collectionSnapshot) => {
          const data = collectionSnapshot.docs.map((doc) => doc.data());
          const _orders: Order[] = [];

          data.forEach((order) => {
            _orders.push({
              created: new Date(order.created.seconds * 1000),
              pickupDate: new Date(order.pickupDate.seconds * 1000),
              name: order.name,
              phone: order.phone,
              comment: order.comment,
              food: order.food,
              finishedOrder: order.finishedOrder,
              accepted: order.accepted,
            });
          });
          setOrders(_orders);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
  }, [menu]);

  return (
    <MaterialTable
      options={{
        exportButton: true,
      }}
      detailPanel={(rowData) => {
        const foodList = rowData.food?.map((f) => {
          return (
            <li>
              {f.amount + " " + f.name + ", " + f.price * f.amount + " kr"}
            </li>
          );
        });
        return rowData.food.length
          ? foodList
          : "Det har skjedd en feil, bestillingen mangler mat";
      }}
      columns={[
        { title: "Navn", field: "name" },
        { title: "Telefon", field: "phone" },
        { title: "Kommentar", field: "comment" },

        {
          title: "Tidspunkt på bestilling",
          field: "created",
          type: "datetime",
        },
        {
          title: "Hentetid",
          field: "pickupDate",
          type: "datetime",
          defaultSort: "asc",
          render: (rowData) => moment(rowData.pickupDate).format("lll"),
        },
      ]}
      data={orders}
      title="Bestillinger per person (alle bestillinger)"
    />
  );
};

export default PersonOrders;
