import { action, createStore } from "easy-peasy";
import { StoreModel } from "./storeModel";

const storeModel: StoreModel = {

  drawerOpen: false,
  toggleDrawer: action((state, payload) => {
      state.drawerOpen = payload;
  }),
  menu: [],
  setMenu: action((state, payload) => {
      state.menu= payload;
    }),
  order: null,
  setOrder: action((state, payload) => {
      state.order= payload;
    }),
  orders: [],
  setOrders: action((state, payload) => {
      state.orders = payload;
    }),
  audio:  new Audio("/CashRegister.mp3")
};

const store = createStore(storeModel);
export default store;