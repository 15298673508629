import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      margin: "8px",
      width: "400px",
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  })
);
const Menu = () => {
  const classes = useStyles();
  const menu = useStoreState((state) => state.menu);
  const order = useStoreState((state) => state.order);
  const setOrder = useStoreActions((actions) => actions.setOrder);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  let history = useHistory();

  const initOrder = () => {
    if (order == null) {
      setOrder({
        name: "",
        created: new Date(),
        pickupDate: new Date(),
        comment: "",
        food: [],
        phone: "",
        finishedOrder: false,
        accepted: false,
        // state: "new",
      });
    }
  };
  useEffect(() => {
    initOrder();
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <div className={classes.wrapper} key={menu.map((e) => e.id).join("-")}>
      <Typography variant="h4">Feriestengt</Typography>
      {/* <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        key={menu.map((e) => e.id).join("-")}
      >
        {menu.length ? (
          menu.map((menuItem, idx) => {
            if (order && !menuItem.deleted) {
              const foodIndex = order.food
                .map((f) => f.id)
                .indexOf(menuItem.id);
              return (
                <Card className={classes.root} key={menuItem.id}>
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        {menuItem.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {menuItem.description}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {menuItem.price} kr
                      </Typography>
                    </CardContent>
                    <div className={classes.controls}>
                      <IconButton
                        aria-label="minus"
                        onClick={() => {
                          if (foodIndex != -1) {
                            if (order.food[foodIndex].amount > 1) {
                              // decrement amount
                              order.food[foodIndex].amount -= 1;
                            } else {
                              // Remove
                              order.food.splice(foodIndex, 1);
                            }
                          } else {
                          }
                          setOrder({
                            name: order.name,
                            created: order.created,
                            pickupDate: order.pickupDate,
                            comment: order.comment,
                            food: order.food,
                            phone: order.phone,
                            finishedOrder: false,
                            accepted: order.accepted,
                            // state: order.state,
                          });
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="subtitle1" color="textSecondary">
                        {foodIndex != -1 ? order.food[foodIndex].amount : 0}
                      </Typography>
                      <IconButton
                        aria-label="add"
                        onClick={() => {
                          if (foodIndex != -1) {
                            // increment amount
                            order.food[foodIndex].amount += 1;
                          } else {
                            order.food.push({
                              amount: 1,
                              id: menuItem.id,
                              name: menuItem.name,
                              price: menuItem.price,
                              state: "new",
                            });
                          }

                          setOrder({
                            name: order.name,
                            created: order.created,
                            pickupDate: order.pickupDate,
                            comment: order.comment,
                            food: order.food,
                            phone: order.phone,
                            finishedOrder: false,
                            accepted: order.accepted,
                          });
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                  <CardMedia
                    className={classes.cover}
                    image={menuItem.image}
                    title="Live from space album cover"
                  />
                </Card>
              );
            }
          })
        ) : (
          <CircularProgress></CircularProgress>
        )}
      </Grid> */}
      {/* <Button
        onClick={() => {
          if (order?.food?.length) {
            history.push("/orderInfo");
          } else {
            setOpenSnackbar(true);
          }
        }}
      >
        Fortsett
      </Button> */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity="error">
          Du må velge mat for å fortsette!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Menu;
