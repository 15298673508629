import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import {
  Button,
  Card,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import Order from "../constants/order";
import { db } from "../FirebaseContext";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";
//@ts-ignore
import Pdf from "./../assets/documents/Personvernerklaring.pdf";
// import DatePicker from "material-ui/DatePicker";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoCard: {
      minWidth: 300,
      padding: theme.spacing(1),
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
const OrderInfo = () => {
  const [waitingTime, setWaitingTime] = useState<number>(15);
  const [defaultDateTime, setDefultDateTime] = useState(moment(new Date()));
  const [pickupDate, setPickupDate] = useState(new Date());

  const order = useStoreState((state) => state.order);
  const setOrder = useStoreActions((actions) => actions.setOrder);
  // const [email, setEmail] = useState<string>(order?.email);
  const [phone, setPhone] = useState<string>(order ? order.phone : "");
  const [name, setName] = useState<string>(order ? order.phone : "");
  const [loading, setLoading] = useState<boolean>();
  const [accepted, setAccepted] = useState<boolean>(
    order ? order.accepted : false
  );

  const [comment, setComment] = useState<string>(order ? order.comment : "");

  const classes = useStyles();

  const requiredString = "Dette feltet må fylles ut.";
  let history = useHistory();

  useEffect(() => {
    if (waitingTime) {
      ValidatorForm.addValidationRule("minDate", (date: Date) => {
        if (moment(date).isBefore(defaultDateTime)) {
          return false;
        }
        return true;
      });
    }
  }, [waitingTime]);

  useEffect(() => {
    // Get inintual waitingTime
    db.collection("settings")
      .doc("waitingTime")
      .get()
      .then((doc) => {
        if (doc?.data()?.waitingTime) {
          setWaitingTime(doc?.data()?.waitingTime);
          setDefultDateTime(defaultDateTime.add(doc?.data()?.waitingTime, "m"));

          setPickupDate(
            moment(new Date())
              .add(doc?.data()?.waitingTime, "m")
              .add(2, "m")
              .toDate()
          );
        }
      });
  }, []);

  // If user refresh page, the food order is lost and therfore they are navigated back
  if (!order?.food.length) {
    history.push("/");
  }
  if (order?.finishedOrder) {
    history.push("/orderResponse");
  }

  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    if (!moment(pickupDate).isBefore(defaultDateTime)) {
      setLoading(true);
      const userOrder: Order = {
        name: name,
        phone: phone,
        pickupDate: pickupDate,
        comment: comment,
        food: order ? order.food : [],
        created: new Date(),
        finishedOrder: true,
        accepted: accepted,
        // state: order ? order.state : "new",
      };
      db.collection("orders")
        .add(userOrder)
        .then((docRef) => {
          docRef.get().then((doc) => {
            const data = doc.data();
            const orderData: Order = {
              comment: data?.comment,
              created: data?.created,
              food: data?.food,
              name: data?.name,
              phone: data?.phone,
              pickupDate: data?.pickupDate,
              accepted: data?.accepted,
              finishedOrder: true,
              // state: data?.state,
            };
            setOrder(orderData);
          });

          history.push("/orderResponse");
          setLoading(false);
        })
        .catch((errors: any) => {
          console.log(errors);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {waitingTime > -1 ? (
        <ValidatorForm
          className={classes.form}
          onSubmit={handleSubmit}
          onError={(errors: any) => {
            console.log(errors);
            setLoading(false);
          }}
        >
          <Card className={classes.infoCard}>
            <br />
            <TextValidator
              label="Navn"
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                setName(target.value);
              }}
              name="name"
              value={name}
              validators={["required"]}
              errorMessages={[requiredString]}
              variant="outlined"
              fullWidth={true}
            />
            <br />
            <TextValidator
              variant="outlined"
              fullWidth={true}
              label="Telefon"
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                setPhone(target.value);
              }}
              name="phone"
              type="tel"
              value={phone}
              validators={[
                "required",
                "minStringLength:8",
                "maxStringLength:8",
                "matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$",
              ]}
              errorMessages={[
                requiredString,
                "For kort nummer.",
                "For langt nummer.",
                "Ugyldig nummer.",
              ]}
            />
            <br />
            <TextValidator
              variant="outlined"
              fullWidth={true}
              label="Kommentar"
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                setComment(target.value);
              }}
              name="comment"
              value={comment}
            />
            <br />
            {/* <TextValidator
              key={defaultDateTime.toString()}
              label="Hentetidspunkt"
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                const date = new Date(target.value);
                setPickupDate(date);
              }}
              name="date-local"
              type="datetime-local"
              value={moment(pickupDate).format().slice(0, 16)}
              validators={["required", "minDate"]}
              errorMessages={[
                requiredString,
                "For tidlig. Det er " + waitingTime + " minutter ventetid.",
              ]}
            /> */}

            <DateTimePicker
              inputVariant="outlined"
              fullWidth={true}
              ampm={false}
              value={pickupDate}
              minDate={defaultDateTime.toDate()}
              onChange={(date) => {
                if (date !== null && date !== undefined) {
                  setPickupDate(date.toDate());
                  console.log(date.toDate());
                }
              }}
              helperText={
                moment(pickupDate).isBefore(defaultDateTime)
                  ? "For tidlig. Det er " + waitingTime + " minutter ventetid."
                  : null
              }
              error={moment(pickupDate).isBefore(defaultDateTime)}
              label="Hentetidspunkt"
            />

            <p>Ventetid: {waitingTime} minutter</p>
            <p>Bjørnstad Lunch er åpent mandag-fredag 10-13 </p>
            {/* <TextValidator
        label="Email"
        onChange={(e) => {
          const target = e.target as HTMLTextAreaElement;
          setName(target.value);
        }}
        name="email"
        value={email}
        validators={["required", "isEmail"]}
        errorMessages={[requiredString, "Emailadressen er ikke riktig"]}
      /> */}

            <Checkbox
              checked={accepted}
              onChange={(e) => {
                setAccepted(e.target.checked);
              }}
              required
              name="checkedB"
              color="primary"
            />
            <span>Jeg godkjenner </span>
            <a href={Pdf} target="_blank">
              personvernserklæringen
            </a>
          </Card>
          <Button type="submit" disabled={loading}>
            Bestill
          </Button>
        </ValidatorForm>
      ) : (
        <CircularProgress></CircularProgress>
      )}
    </>
  );
};
export default OrderInfo;
