import { auth } from "../FirebaseContext";

const SignOut = () => {
  let res = "";
  auth
    .signOut()
    .then(() => {
      // Sign-out successful.
      res = "Du er logget ut";
    })
    .catch((error) => {
      // An error happened.
      res = "Du skjedde en feil";
    });
  return <p>{res}</p>;
};
export default SignOut;
