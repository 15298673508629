import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useStoreState } from "../hooks/storeHooks";
import moment from "moment";
//@ts-ignore
import localization from "moment/locale/nb";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 700,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const OrderResponse = (props: any) => {
  const order = useStoreState((state) => state.order);
  const classes = useStyles();

  if (!order) {
    return (
      <p>
        Finner ikke bestillingen din. Hvis du tror du har bestillt anbefaler vi
        å ringe for å se om bestillingen din gikk gjennom.
      </p>
    );
  }

  const foodList = order?.food?.map((f) => {
    return (
      <li>{f.amount + " " + f.name + ", " + f.price * f.amount + " kr"}</li>
    );
  });
  moment.locale("nb", localization);

  const pickupDate = moment(
    //@ts-ignore
    new Date(order?.pickupDate.seconds * 1000)
  ).calendar();

  return (
    <div className={classes.wrapper}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2">
            Din bestilling
          </Typography>
          <Typography variant="body2" component="p">
            {foodList}
            <br />
            Hent maten din på Dyrendalsveien 40.
            <br /> Hentetidspunkt:
            {"\t" + pickupDate}
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button
            disabled={true}
            variant="outlined"
            size="small"
            onClick={() => alert("Ikke implementert")}
          >
            Last ned
          </Button>
        </CardActions> */}
      </Card>
    </div>
  );
};
export default OrderResponse;
