import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Menu from "./pages/menu";
import { StoreProvider } from "easy-peasy";
import store from "./store/store";
import Orders from "./pages/orders";
import EditMenu from "./pages/editMenu";
import "firebase/auth";
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider,
} from "@react-firebase/auth";
import firebase from "firebase";
import { firebaseConfig } from "./FirebaseContext";
import SignIn from "./pages/signIn";
import SignOut from "./pages/signOut";
import OrderInfo from "./pages/orderInfo";
import OrderResponse from "./pages/orderResponse";
import PersonOrders from "./pages/personOrders";
import Visualization from "./pages/visualization";
import Settings from "./pages/settings";
import Layout from "./components/Layout";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

function App() {
  return (
    <StoreProvider store={store}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <header className="App-header"></header>

          <Router>
            <Layout>
              <div>
                <Switch>
                  <Route path="/orderResponse">
                    <OrderResponse />
                  </Route>
                  <Route path="/orderInfo">
                    <OrderInfo />
                  </Route>
                  <Route path="/signOut">
                    <SignOut />
                  </Route>
                  <Route path="/signIn">
                    <SignIn />
                  </Route>
                  <Route path="/editMenu">
                    <FirebaseAuthConsumer>
                      {({ isSignedIn, user, providerId }) => {
                        if (isSignedIn) {
                          return <EditMenu />;
                        }
                        return <SignIn />;
                      }}
                    </FirebaseAuthConsumer>
                  </Route>
                  <Route path="/admin">
                    <FirebaseAuthConsumer>
                      {({ isSignedIn, user, providerId }) => {
                        if (isSignedIn) {
                          return <Redirect to="/editMenu" />;
                        }
                        return <SignIn />;
                      }}
                    </FirebaseAuthConsumer>
                  </Route>

                  <Route path="/visualization">
                    <FirebaseAuthConsumer>
                      {({ isSignedIn, user, providerId }) => {
                        if (isSignedIn) {
                          return <Visualization />;
                        }
                        return <SignIn />;
                      }}
                    </FirebaseAuthConsumer>
                  </Route>
                  <Route path="/settings">
                    <FirebaseAuthConsumer>
                      {({ isSignedIn, user, providerId }) => {
                        if (isSignedIn) {
                          return <Settings />;
                        }
                        return <SignIn />;
                      }}
                    </FirebaseAuthConsumer>
                  </Route>
                  <Route path="/personOrders">
                    <FirebaseAuthConsumer>
                      {({ isSignedIn, user, providerId }) => {
                        if (isSignedIn) {
                          return <PersonOrders />;
                        }
                        return <SignIn />;
                      }}
                    </FirebaseAuthConsumer>
                  </Route>
                  <Route path="/orders">
                    <FirebaseAuthConsumer>
                      {({ isSignedIn, user, providerId }) => {
                        if (isSignedIn) {
                          return <Orders />;
                        }
                        return <SignIn />;
                      }}
                    </FirebaseAuthConsumer>
                  </Route>
                  <Route path="/">
                    <Menu />
                  </Route>
                </Switch>
              </div>
            </Layout>
          </Router>
        </MuiPickersUtilsProvider>
      </FirebaseAuthProvider>
    </StoreProvider>
  );
}

export default App;
