import { Button, Slide } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import MenuItem from "../constants/menuItem";
import { db, storage } from "../FirebaseContext";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EditMenu = () => {
  const setMenu = useStoreActions((actions) => actions.setMenu);
  const menu = useStoreState((state) => state.menu);

  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [imageAsFile, setImageAsFile] = useState<File>();
  const [currentDish, setCurrentDish] = useState<string>("");

  const onRowUpdate = async (newData: any, oldData: any) => {
    if (newData !== oldData) {
      db.collection("menu")
        .doc(newData.id)
        .set(newData)
        .then(() => {
          const dataUpdate = [...menu];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          console.log(dataUpdate);

          const _menu: MenuItem[] = menu.map((mitem) => {
            if (mitem.id === oldData.id) {
              mitem = newData;
            }
            return mitem;
          });

          // const _menu = menu.filter((dish) => dish.id !== oldData.id);
          setMenu(_menu);

          // setMenu([...dataUpdate]);
        });
    }
  };

  const handleImageAsFile = (e: any) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
  };

  const handleFireBaseUpload = (e: any) => {
    setOpenImageDialog(false);
    e.preventDefault();
    // async magic goes here...
    if (!imageAsFile) {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    } else {
      const uploadTask = storage
        .ref(`/images/${imageAsFile.name}`)
        .put(imageAsFile);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("images")
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              db.collection("menu")
                .doc(currentDish)
                .update({ image: fireBaseUrl });
              //  setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
            });
        }
      );
    }
  };

  return (
    <div>
      <MaterialTable
        editable={{
          // onBulkUpdate: (changes) =>
          //   new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //       /* setData([...data, newData]); */
          //     }, 1000);
          //   }),
          onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
          onRowUpdateCancelled: (rowData) =>
            console.log("Row editing cancelled"),
          onRowAdd: (newData) =>
            new Promise<any>((resolve, reject) => {
              db.collection("menu")
                .add(newData)
                .then((doc) => {
                  newData.id = doc.id;
                  setMenu([...menu, newData]);

                  resolve({});
                })
                .catch(() => console.log("Fail"));
            }),
          onRowUpdate: onRowUpdate,
          onRowDelete: (oldData) =>
            new Promise<any>((resolve, reject) => {
              db.collection("menu")
                .doc(oldData.id)
                .update({ deleted: true })
                .then(() => {
                  const _menu: MenuItem[] = menu.map((mitem) => {
                    if (mitem.id === oldData.id) {
                      mitem.deleted = true;
                    }
                    return mitem;
                  });

                  // const _menu = menu.filter((dish) => dish.id !== oldData.id);
                  setMenu(_menu);
                  resolve({});
                });
            }),
        }}
        columns={[
          { title: "Navn", field: "name" },
          { title: "Pris", field: "price" },
          { title: "Beskrivelse", field: "description" },
          {
            field: "image",
            title: "Bilde",
            editable: "never",
            render: (rowData) => {
              if (rowData.image) {
                return (
                  <img src={rowData.image} style={{ width: 50, height: 50 }} />
                );
              }
              return;
            },
          },
        ]}
        data={menu.filter((m) => !m.deleted)}
        title="Endre meny"
        actions={[
          {
            icon: "insert_photo",
            tooltip: "Endre/legg til bilde",
            onClick: (event, rowData) => {
              //@ts-ignore This can never ba an array of MenuItems
              setCurrentDish(rowData.id);
              setOpenImageDialog(true);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          addRowPosition: "first",
        }}
      />
      <Dialog
        open={openImageDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Endre eller legg til et bilde.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <input type="file" onChange={handleImageAsFile} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageDialog(false)} color="primary">
            Lukk
          </Button>
          <Button onClick={handleFireBaseUpload} color="primary" autoFocus>
            Fullfør
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditMenu;
