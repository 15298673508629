import Chart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import Order from "../constants/order";
import { db } from "../FirebaseContext";
import { useStoreState } from "../hooks/storeHooks";

const Visualization = () => {
  const menu = useStoreState((state) => state.menu);
  const [orders, setOrders] = useState<Order[]>([]);

  const series = [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  useEffect(() => {
    if (menu) {
      db.collection("orders").onSnapshot(
        (collectionSnapshot) => {
          const data = collectionSnapshot.docs.map((doc) => doc.data());
          const _orders: Order[] = [];

          data.forEach((order) => {
            _orders.push({
              created: new Date(order.created.seconds * 1000),
              pickupDate: new Date(order.pickupDate.seconds * 1000),
              name: order.name,
              phone: order.phone,
              comment: order.comment,
              food: order.food,
              finishedOrder: order.finishedOrder,
              accepted: order.accepted,
              // state: order.state,
            });
          });
          setOrders(_orders);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
  }, [menu]);
  return (
    <div>
      <p>
        Dette er bare et eksempel på noe du kansje vil ha. Kan fo eksemple være
        en graf over hvor mye du har solgt de forskjellige dagen.
      </p>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};
export default Visualization;
